/* kontaktaoss */

.gcs-author-details {
  @apply flex items-center bg-gray-100 p-3;
}
.gcs-author-details img {
  @apply mr-6 max-w-[120px] rounded-full;
}
.gcs-author-info {
  @apply font-normal text-headline-2xs;
}
.gcs-author-name {
  @apply text-headline-sm;
}
.gcs-author-title {
  @apply mt-1;
}
.gcs-author-email {
  @apply text-primary-600 mt-1;
}

/* 
 * Temporary hack to get squeeze front page layout  
 * Remove this after moving to Lab4 and changing the page layout
*/
@media (min-width: 1040px) {
  .md\:col-span-6:first-of-type {
    grid-column: 3 / span 4 !important;
  }

  .md\:col-span-6:last-of-type {
    grid-column: 7 / span 4 !important;
  }
}

.bg-sv-secondary-40 {
  @apply bg-secondary-500;
}

.sm\:bg-sv-secondary-40 {
  @apply sm:bg-secondary-500;
}

.bg-sv-royal-blue-80 {
  @apply bg-royal-blue-700;
}

.sm\:bg-sv-royal-blue-80 {
  @apply sm:bg-royal-blue-700;
}

.bg-sv-gray-80 {
  @apply bg-gray-600;
}

.sm\:bg-sv-gray-80 {
  @apply sm:bg-gray-600;
}

.bg-sv-primary-700 {
  @apply bg-primary-700;
}

.sm\:bg-sv-primary-700 {
  @apply sm:bg-primary-700;
}

.text-sv-red {
  @apply text-primary-700;
}

.sm\:text-sv-red {
  @apply sm:text-primary-700;
}
